<template>
	<div class="app-body">
		<div class="a-flex-rsbc a-ptb-6">
			<span class="a-fs-16 a-fw-b">巡检任务列表</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" class="s-btn-add" @click="editWorkOrder">创建巡检</el-button>
                <!-- <el-button icon="el-icon-upload2" class="s-btn-exp">导出</el-button> -->
            </div>
		</div>
		<el-card class="el-main">
            <div class="a-flex-rfsc">
                <div class="a-flex-ccc complaint-item a-c-white" :style="statusStyle[item.value]" v-for="(item,index) in statusDic" :key="index" @click="handleClickCount(item.value)">
                    <span class="title" :style="statusColorStyle[item.value]">{{ item.label }}</span>
                    <span class="waitNum" :style="statusColorStyle[item.value]">{{ pageCount[item.value] }}</span>
                </div>
            </div>
			<le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
				<le-input label="任务ID" v-model="pageParam.params.sn" placeholder="请输入内容" />
                <le-select-local-search label="站点类型" v-model="pageParam.params.stationType" :options="stationTypeDic" />
                <le-select-local-search label="状态" v-model="pageParam.params.status" :options="statusDic" />
                <le-select-local-search label="重要程度" v-model="pageParam.params.importantLevel" :options="importanceLevelDic" />
                <le-select-local-search label="异常状态" multiple v-model="pageParam.params.errorStatus" :options="patrolErrorStatusDic" />
                <le-select-local-search label="设备离线" v-model="pageParam.params.offlineDevice" :options="offlineDeviceDic" />
                <le-select-remote-search 
                    label="巡检点"
                    v-model="pageParam.params.stationId" 
                    :options="optionsStation" 
                    placeholder="请选择站点（可输入搜索）" />
                <le-select-local-search label="巡检类型" v-model="pageParam.params.patrolType" :options="patrolTypeDic" />
                <le-date-range 
                    label="发布时间" 
                    :minDate.sync="pageParam.params.sendStartTime" 
                    :maxDate.sync="pageParam.params.sendEndTime" 
                    :pickerOptions='pickerOptions' 
                    :defaultTime="['00:00:00','23:59:59']"
                    valueFormat="yyyy-MM-dd HH:mm:ss" />
                <le-select-remote-search 
                    v-model="pageParam.params.solveRbacUserId" 
                    label="巡检人"
                    :options="optionsSolveRbacUser" 
                    placeholder="请选择巡检人（可输入搜索）" />
                <le-input-district-choose 
                    label="省市区" 
                    :province.sync="pageParam.params.provinceCode" 
                    :city.sync="pageParam.params.cityCode" 
                    :area.sync="pageParam.params.districtCode">
                </le-input-district-choose>
                <!-- <le-input label="地址" v-model="pageParam.params.address" placeholder="请输入内容" /> -->
                <le-date-range 
                    label="完成时间" 
                    :minDate.sync="pageParam.params.doneStartTime" 
                    :maxDate.sync="pageParam.params.doneEndTime" 
                    :pickerOptions='pickerOptions'
                    :defaultTime="['00:00:00','23:59:59']"
                    valueFormat="yyyy-MM-dd HH:mm:ss" />
			</le-search-form>
			<le-pagview ref="inspectionPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.inspectionList">
                <template slot="headerLabel" slot-scope="scope" >
                    <div class="a-flex-rfsc">
                        <span class="a-fs-14 a-c-master a-fw-700 ">已选择：</span>
                        <el-dropdown placement="bottom-start" @command="handlerOperationBatch">
                            <el-button size='mini' type="primary" class="a-mlr-12">批量操作</el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command='"gb"'>关闭</el-dropdown-item>
                                <el-dropdown-item :command='"bgzxr"'>指派</el-dropdown-item>
                                <el-dropdown-item :command='"zycd"'>重要程度</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-button size='mini' type="primary" plain @click="handleDeleteCancelAll">取消批量</el-button>
                        <el-button size='mini' type="primary" @click="handleConfirmAll">确认选择</el-button>
                    </div>
                    <div class="checkedDevList a-flex-wrap a-flex-rfsc a-mt-6" v-show="selectData.length">
                        <el-tag
                            class="a-mr-08 a-mb-05"
                            v-for="tag in selectData"
                            :key="tag.sn"
                            @close="handleDeleteSelect(tag)"
                            closable>
                            {{tag.sn}}
                        </el-tag>
                    </div>
                </template>
				<el-table 
                    ref="inspectionList" 
                    :data="tableData" 
                    :highlight-current-row="true" 
                    :row-key="getRowKey"
                    @selection-change="handleSelectionChange"
                    style="width: 100%" 
                    v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column type="selection" :selectable='handleSelectable' fixed="left" align="center" width="55" reserve-selection  class="device-checkBox"></el-table-column>
					<el-table-column prop="sn" label="任务ID" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.sn || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="orderId" label="巡检点" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.stationName || "" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="orderId" label="巡检点ID" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.stationId || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="orderId" label="重要程度" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.importanceLevel | initDic(importanceLevelDic) }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="orderId" label="状态" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.status | initDic(statusDic) }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="orderId" label="异常情况" min-width="120">
                        <template slot-scope="{ row }">
							<span :class="[row.errorStatus == 2 ? 'a-c-error' : '']">{{ row.errorStatus | initDic(patrolErrorStatusDic) }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="orderId" label="异常处理" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.doneError }}/{{ row.errorTotal }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="orderId" label="站点类型" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.stationType | initDic(stationTypeDic) }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="orderId" label="巡检类型" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.patrolType | initDic(patrolTypeDic) }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="orderId" label="巡检模版" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.patrolTemplateName || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="orderId" label="地址" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.address || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="orderId" label="发布时间" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.sendTime || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="orderId" label="截止时间" min-width="120">
                        <template slot-scope="{ row }">
							<span :class="[$getDay.getDateTimeDiff(new Date().getTime(),row.endTime) <= 24 ? 'a-c-error':'']">{{ row.endTime || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="orderId" label="完成时间" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.completeTime || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="orderId" label="任务时限" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row | initInspectionTime }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="orderId" label="执行人" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.solveRbacUserName || "" }} - {{ row.solveRbacUserPhone }}</span>
						</template>
					</el-table-column>
                    
                    <el-table-column prop="orderId" label="关联工单" min-width="120">
                        <template slot-scope="{ row }">
                            <div class="a-flex-wrap">
                                <span v-for="(item, index) in row.workOrderVoList" :key="index">{{ item.errorType | initDic(errorTypeDic) }}/</span>
                            </div>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="80" fixed="right">
						<template slot-scope="scope">
							<el-tooltip class="item" effect="dark" content="巡检详情">
								<img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="handleToInfo(scope.row)" />
							</el-tooltip>
                            <el-tooltip class="item a-ml-12" effect="dark" content="更多操作" placement="top">
                                <el-dropdown placement="bottom-start" @command="handlerOperation">
                                    <img src="../../assets/icon/more-operation.png" class="a-wh-16" />
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item 
                                            :command='"gb"+"#"+scope.$index' 
                                            :disabled='!(scope.row.status != 3 && scope.row.status != 5 && scope.row.status != 7 && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88))'>关闭</el-dropdown-item>
                                        <!-- <el-dropdown-item :command='"jcgq"+"#"+scope.$index'>解除挂起</el-dropdown-item> -->
                                        <el-dropdown-item 
                                            :command='"bgzxr"+"#"+scope.$index' 
                                            :disabled='!(scope.row.status != 3 && scope.row.status != 5 && scope.row.status != 7 && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88))'
                                            >指派</el-dropdown-item>
                                        <el-dropdown-item 
                                            :command='"zycd"+"#"+scope.$index' 
                                            :disabled='!(scope.row.status != 3 && scope.row.status != 5 && scope.row.status != 7 && (userInfo.dataPermission == 99 || userInfo.dataPermission == 88))'
                                            >重要程度</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </el-tooltip>
						</template>
					</el-table-column>
				</el-table>
			</le-pagview>
		</el-card>
        <change-executor ref="changeExecutor" @handlerSuccess='handlerChangeExecutorSuccess' :tableDataItem='currentDatas' type='inspection'></change-executor>
        <change-import-level ref="importLevel" @handlerSuccess='handlerChangeImportantSuccess' :tableDataItem='currentDatas'></change-import-level>
        <batch-error ref="batchError" :tableData="batchErrorList" @handlerReTry='handlerReTry'></batch-error>
	</div>
</template>

<script>
let _this
import util from "../../utils/util.js";
import changeExecutor from '../workOrder/child/change-executor.vue';
import ChangeImportLevel from '../workOrder/child/change-importLevel.vue';
import batchError from '../workOrder/child/batch-error.vue';
import { mapState } from 'vuex';
_this
export default {
  components: { changeExecutor, ChangeImportLevel, batchError },
	data() {
		return {
			util: util,
			tableData: [],
			pageParam: {
				url: this.$Config.apiUrl.inspectionPage,
				method: "post",
				params: {
					provinceCode: "",
                    cityCode: "",
					districtCode: "",
                    sn: '',
                    stationType: '',
                    status: '',
                    importantLevel: '',
                    stationId: '',
                    patrolType: '',
                    sendStartTime: '',
                    sendEndTime: '',
                    solveRbacUserId: '',
                    doneStartTime: '',
                    doneEndTime: '',
                    offlineDevice: '',
                    errorStatus: []
				},
                freshCtrl: 1,
			},
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(_this.$getDay.getTodayLastTime('timeStamp') - 3600 * 1000 * 24 * 6);
                        end.setTime(_this.$getDay.getTodayLastTime('timeStamp'));
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(_this.$getDay.getTodayLastTime('timeStamp') - 3600 * 1000 * 24 * 30);
                        end.setTime(_this.$getDay.getTodayLastTime('timeStamp'));
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(_this.$getDay.getTodayLastTime('timeStamp') - 3600 * 1000 * 24 * 91);
                        end.setTime(_this.$getDay.getTodayLastTime('timeStamp'));
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            optionsStation: {
                url: this.$Config.apiUrl.getStationInfoList,
                method: "post",
                params: {},
                showLoading: false,
                labelKey: "name",
                valueKey: "id",
                searchKey: "searchKey",
                pageSize: 100
            },//站点数据
            optionsSolveRbacUser: {
                url: this.$Config.apiUrl.getExecutor,
                method: "post",
                params: {
                },
                showLoading: false,
                labelKey: "userName",
                otherLabelKey: "companyName",
                valueKey: "id",
                searchKey: "name", 
                pageSize: 200
            },
            stationTypeDic: [],
            statusDic: [],
            importanceLevelDic: [],
            patrolTypeDic: [],
            errorTypeDic: [],
            patrolErrorStatusDic: [],
            offlineDeviceDic: [{
                label: '有',
                value: 1
            },{
                label: '没有',
                value: 0
            }],
            currentDatas: '',
            currentBatch: '',
            selectData: [],
            batchErrorList: [],
            pageCount: {},
            statusStyle: {
                1: 'background: #E6A23C40',
                2: 'background: #007af040',
                3: 'background: #67C23A40',
                4: 'background: #F56C6C40',
                5: 'background: #FF484840',
                6: 'background: #24d4d040',
                7: 'background: #66666640',
            },
            statusColorStyle: {
                1: 'color: #E6A23C',
                2: 'color: #007af0',
                3: 'color: #67C23A',
                4: 'color: #F56C6C',
                5: 'color: #FF4848',
                6: 'color: #24d4d0',
                7: 'color: #666666',
            }
		}
	},
    computed:{
        ...mapState({
            company: state => state.company.company,
            userInfo: state => state.user.user,
        })
    },
    created () {
        this.$getDic('stationType','select').then(res=>{ this.stationTypeDic = res; })
        this.$getDic('importLevel','select').then(res=>{ this.importanceLevelDic = res; })
        this.$getDic('patrolStatus','select').then(res=>{ this.statusDic = res; })
        this.$getDic('patrolType','select').then(res=>{ this.patrolTypeDic = res; })
        this.$getDic('PatrolErrorStatus','select').then(res=>{ this.patrolErrorStatusDic = res; })
        
        this.getErrorTypeDic()
        _this = this
    },
    activated () {
        this.pageParam.freshCtrl++;
        this.getInspectionStatus()
    }, 
    filters: {
        initInspectionTime (row) {
            let time1 = _this.$getDay.getTimeUnix(row.sendTime)
            let time2 = _this.$getDay.getTimeUnix(row.endTime)
            let diff = time2 - time1
            if(diff <= 0) {
                return '-'
            }else if (diff >= (48 * 60 * 60 * 1000)) {
                return Math.ceil(diff / (24 * 60 * 60 * 1000)) + '天'
            }else {
                let hour = parseInt(diff / (60 * 60 * 1000))
				let minute = diff % (60 * 60 * 1000)
                minute = parseInt(minute / (60 * 1000))
				return (hour?hour + '小时':'') + (minute?minute:0) + '分钟'
            }
        }
    },
	mounted() { },
	methods: {
        getErrorTypeDic () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderErrorType,
                method: "post",
                params: {},
            })
            .then(({ data })=>{
                this.errorTypeDic = data.map(item=>{
                    return {
                        label: item.name,
                        value: item.id
                    }
                })
            })
            .catch(err=>{

            })
        },
        getInspectionStatus () {
            this.$Axios._post({
                url: this.$Config.apiUrl.inspectionStatus,
                method: "post",
                params: {
                    ...this.pageParam.params
                },
            })
            .then(({ data })=>{
                this.pageCount = data.reduce((acc, {status, num}) => {
                    acc[status] = num;
                    return acc;
                }, {});
            })
            .catch(err=>{

            })
		},
		setTableData(data) {
			this.tableData = data;
		},
        handlerRest() {
			this.pageParam.params = {
				provinceCode: "",
                cityCode: "",
                districtCode: "",
                sn: '',
                stationType: '',
                status: '',
                importantLevel: '',
                stationId: '',
                patrolType: '',
                sendStartTime: '',
                sendEndTime: '',
                solveRbacUserId: '',
                doneStartTime: '',
                doneEndTime: '',
                offlineDevice: '',
                errorStatus: []
			};
			this.handlerSearch()
		},
		handlerSearch() {
			this.$refs['inspectionPage'].pageNum = 1
			this.pageParam.freshCtrl++;
            this.getInspectionStatus()
		},
        // 创建巡检
        editWorkOrder () {
            this.$router.push({
                path:'/inspection/inspection-edit',
            })
        },
        handleToInfo (datas) {
            this.$router.push({
                path:'/inspection/inspection-info',
                query: {
                    id: datas.id?datas.id:'',
                    questionPaperId: datas.questionPaperId,
                    answerPaperId: (datas.questionAnswerId ? datas.questionAnswerId : '')
                }
            })
        },
        getRowKey (row) {
            return row.id
        },
        handleSelectionChange (selection) {
            this.selectData = selection
        },
        handleDeleteSelect (datas) {
            this.selectData.splice(this.selectData.indexOf(datas), 1);
            this.$refs['inspectionList'].toggleRowSelection(datas)
        },
        handleDeleteSelectAll () {
            this.$refs['inspectionList'].clearSelection()
            this.selectData = []
        },
        handlerReTry () {
            this.handleConfirmAll()
        },
        handleConfirmAll () {
            if(!this.selectData.length) {
                this.$message.error('请至少选择一项数据')
                return false
            }
            let reqData
            switch (this.currentBatch) {
                case 'gb':
                    // 挂起
                    this.$prompt('请输入备注', '是否确认关闭？', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    }).then(({ value })=>{
                        let ids = this.selectData.map(item=>{
                            return item.id
                        })
                        let reqData = {
                            patrolIdList: ids,
                            remark: value
                        }
                        this.handleSubmitOperate(reqData, this.$Config.apiUrl.inspectionPause, 'post')
                    }).catch(err=>{
                        console.log(err);
                    })
                    break;
                case 'jcgq':
                    // 解除挂起
                    
                    break;
                case 'bgzxr':
                    // 变更执行人
                    this.$refs['changeExecutor'].dialogVisible = true
                    break;
                case 'zycd':
                    // 修改重要程度
                    this.$refs['importLevel'].dialogVisible = true
                    break;
                default:
                    break;
            }
        },
        // 修改执行人
        handlerChangeExecutorSuccess (datas) {
            let ids = this.selectData.map(item=>{
                return item.id
            })
            let reqData = {
                patrolId: this.currentDatas.id,
                patrolIdList: ids,
                rbacUserId: datas.solveRbacUser,
                remark: datas.remark
            }
            this.handleSubmitOperate(reqData, this.$Config.apiUrl.inspectionChangeSolveUser, 'post')
        },
        handlerChangeImportantSuccess (datas) {
            let ids = this.selectData.map(item=>{
                return item.id
            })
            let reqData = {
                patrolId: this.currentDatas.id,
                patrolIdList: ids,
                ...datas,
            }
            this.handleSubmitOperate(reqData, this.$Config.apiUrl.inspectionImportance, 'post')
        },
        // 更多操作
        handlerOperation (commands) {
            let command = commands.split('#')[0]
            let index = commands.split('#')[1]
            switch (command) {
                case 'gb':
                    // 挂起
                    this.$prompt('请输入备注', '是否确认关闭？', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    }).then(({ value })=>{
                        let reqData = {
                            patrolId: this.tableData[index].id,
                            remark: value
                        }
                        this.handleSubmitOperate(reqData, this.$Config.apiUrl.inspectionPause, 'post')
                    }).catch(err=>{
                        console.log(err);
                    })
                    break;
                case 'jcgq':
                    // 解除挂起
                    this.$confirm('是否确认解除挂起?', '温馨提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(_=>{
                       
                    }).catch(_=>{})
                    break;
                case 'bgzxr':
                    // 变更执行人
                    this.currentDatas = this.tableData[index]
                    this.$refs['changeExecutor'].dialogVisible = true
                    break;
                case 'zycd':
                    // 修改重要程度
                    this.currentDatas = this.tableData[index]
                    this.$refs['importLevel'].dialogVisible = true
                    break;
                default:
                    break;
            }
        },
        handlerOperationBatch (commands) {
            this.currentBatch = commands
        },
        handleDeleteCancelAll () {
            this.currentBatch = ""
            this.handleDeleteSelectAll()
        },
        handleSelectable (row,index) {
            let isSelect = false
            switch (this.currentBatch) {
                case 'gb':
                    // 挂起
                    isSelect = (row.status != 3 && row.status != 5 && row.status != 7 && (this.userInfo.dataPermission == 99 || this.userInfo.dataPermission == 88))
                    break;
                case 'bgzxr':
                    // 变更执行人
                    isSelect = (row.status != 3 && row.status != 5 && row.status != 7 && (this.userInfo.dataPermission == 99 || this.userInfo.dataPermission == 88))
                    break;
                case 'zycd':
                    // 修改重要程度
                    isSelect = (row.status != 3 && row.status != 5 && row.status != 7 && (this.userInfo.dataPermission == 99 || this.userInfo.dataPermission == 88))
                    break;
                default:
                    break;
            }
            return isSelect
        },
        handleSubmitOperate (reqData, apiUrl, method) {
            if(method == 'get') {
                this.$Axios._get({
                    url: apiUrl,
                    method: method,
                    params: {
                        ...reqData
                    },
                })
                .then(({data})=>{
                    this.currentDatas = ''
                    this.pageParam.freshCtrl++;
                    if(data.length) {
                        this.batchErrorList = data
                        let arr = []
                        this.$refs['batchError'].dialogVisible = true
                        this.selectData.map(item=>{
                            let targetIndex = this.batchErrorList.findIndex(ite=>{
                                return ite.id == item.id
                            })
                            if(targetIndex != -1) {
                                arr.push(item)
                            }else {
                                this.$refs['inspectionList'].toggleRowSelection(item)
                            }
                        })
                        this.selectData = arr
                    }else {
                        this.handleDeleteCancelAll()
                    }
                })
                .catch(err=>{
                    console.log(err);
                })
            }else {
                this.$Axios._post({
                    url: apiUrl,
                    method: method,
                    params: {
                        ...reqData
                    },
                })
                .then(({data})=>{
                    this.currentDatas = ''
                    this.pageParam.freshCtrl++;
                    if(data.length) {
                        this.batchErrorList = data
                        let arr = []
                        this.$refs['batchError'].dialogVisible = true
                        this.selectData.map(item=>{
                            let targetIndex = this.batchErrorList.findIndex(ite=>{
                                return ite.id == item.id
                            })
                            if(targetIndex != -1) {
                                arr.push(item)
                            }else {
                                this.$refs['inspectionList'].toggleRowSelection(item)
                            }
                        })
                        this.selectData = arr
                    }else {
                        this.handleDeleteCancelAll()
                    }
                })
                .catch(err=>{
                    console.log(err);
                })
            }
            
        },
        handleClickCount (code) {
            this.pageParam.params.status = code
            this.handlerSearch()
        },
	},
};
</script>

<style lang="scss" scoped>
    .s-btn-add{
        width: 105px;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0 !important;
        height: 0 !important;
    }
    /deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
        height: 100% !important;
    }
    .complaint-item{
        margin-right: 20px;
        width: 100px;
        height: 48px;
        border-radius: 8px;

        .title{
            font-size: 14px;
            font-weight: 500;
        }
        .waitNum{
            font-size: 16px;
            font-weight: 700;
            // color: #007AFF;
        }
        .waitText{
            font-size: 16px;
            font-weight: 400;
            // color: #007AFF;
        }
        .overtimeText{
            font-size: 14px;
            color: #666666;
        }
        .overtimeNum{
            font-size: 20px;
            color: #F56C6C;
        }
    }
</style>
