<template>
    <div>
        <el-dialog
            title="以下数据操作失败，是否重试？"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="800px">
            <div class="formContent">
                <el-table 
                    :data="tableData" 
                    style="width: 100%" >
                    <el-table-column prop="title" label="编号" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.sn || "-" }}</span>
						</template>
					</el-table-column>
                    <el-table-column prop="sn" label="失败原因" min-width="120">
                        <template slot-scope="{ row }">
							<span>{{ row.reason || "-" }}</span>
						</template>
					</el-table-column>
                </el-table>
            </div>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">重试</el-button>
            </div>
        </el-dialog>
        
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
            };
        },
        props: ['tableData'],
        methods:{
            submit () {
                this.$emit('handlerReTry')
            },
        }
    }
</script>

<style lang="scss" scoped>
    .tableTitle{
        font-size: 14px;
        color: #333333;
        font-weight: 700;
        margin: 24px 0;
        display: block;
    }
    /deep/ .el-dialog__wrapper{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    /deep/ .el-dialog__body{
        padding: 10px 20px !important;
    }
    /deep/ .el-dialog{
        margin: 0 auto !important;
    }
    /deep/ .is-disabled{
        color: #333333 !important;
    }
    .formContent{
        max-height: 70vh;
        overflow: auto;
        padding-right: 10px;
    }
    .tips{
        font-size: 12px;
        color: #909399;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        line-height: 22px;
    }
</style>